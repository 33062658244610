import { useEffect } from 'preact/hooks';
import { Suspense, lazy } from 'preact/compat';

import Login from './Login';
import CloseControl from './CloseControl';
import Profile from './Components/Profile/Profile';
import RequestFields from './RequestFields';
import RequestForm from './Components/RequestForm/RequestForm';
import { useRoute } from './hooks';
import { useGlobalContext, ActionType } from './hooks/use-global-context';
import classNames from 'classnames';
import ConnectAuthenticator from './Components/ConnectAuthenticator/ConnectAuthenticator';
import MergeAccount from './MergeAccount';
import WalletDetails from './Components/WalletDetails/WalletDetails';
import CreateNamedAccount from './Components/WalletDetails/Near/CreateNamedAccount/CreateNamedAccount';
import ContainerInner from './ContainerInner';
import CollectInformation from './Components/CollectInformation/CollectInformation';
import LegalOptIn from './Components/LegalOptIn/LegalOptIn';
import { MessageType, sendMessageToApp } from './utils/mobile-app';
import DeleteAccount from './Components/DeleteAccount/DeleteAccount';
import useHandleClose from '@/scripts/hooks/use-handle-close';

const TicketsLazy = lazy(() => import('./Components/YourTickets/YourTickets'));

export default function Account() {
  const { navTo } = useRoute();
  const { state, dispatch } = useGlobalContext();
  const { nav } = state;
  const { current_route } = nav;
  const handleClose = useHandleClose();

  useEffect(() => {
    if (current_route === '/account') {
      navTo('/account/manage', 'account', { is_container_visible: state.is_container_visible });
    }

    dispatch({
      type: ActionType.SET_SECTION,
      payload: {
        section: 'account',
      },
    });
  }, [current_route, dispatch, navTo, state.is_container_visible]);

  useEffect(() => {
    if (!nav?.options?.prevent_closing || state.config?.displayContext !== 'mobile_app') {
      return;
    }

    sendMessageToApp({
      type: MessageType.CAN_TOUCH_BACKGROUND_TO_DISMISS,
      payload: {
        enable: 'false',
      },
    });

    return () => {
      sendMessageToApp({
        type: MessageType.CAN_TOUCH_BACKGROUND_TO_DISMISS,
        payload: {
          enable: 'true',
        },
      });
    };
  }, [nav?.options?.prevent_closing, state.config?.displayContext]);

  return (
    <ContainerInner
      customClass={classNames({ 'rph-container-inner__request-fields': current_route === '/account/requestFields' })}
    >
      {({ closeModal }) => {
        return (
          <>
            {!nav?.options?.prevent_closing && current_route !== '/account/login' && (
              <CloseControl onClose={closeModal} />
            )}
            {current_route === '/account/login' && <Login onClose={closeModal} />}
            {current_route === '/account/manage' && <Profile />}
            {current_route === '/account/requestFields' && <RequestFields />}
            {current_route === '/account/connectAuthenticator' && <ConnectAuthenticator />}
            {current_route === '/account/merge' && <MergeAccount />}
            {current_route === '/account/requestForm' && <RequestForm />}
            {current_route === '/account/walletDetails' && <WalletDetails />}
            {current_route === '/account/near/createNamedAccount' && <CreateNamedAccount />}
            {current_route === '/account/collectInformation' && <CollectInformation />}
            {current_route === '/account/legalOptIn' && <LegalOptIn />}
            {current_route === '/account/delete' && <DeleteAccount />}
            {current_route === '/account/tickets' && (
              <Suspense fallback={null}>
                <TicketsLazy onClose={closeModal || handleClose} />
              </Suspense>
            )}
          </>
        );
      }}
    </ContainerInner>
  );
}
